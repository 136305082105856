<svg
    {...$$props}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <path
        d="M9.20005 9.69999C9.20005 11.3 10.5 12.5 12 12.5C13.5 12.5 14.8 11.2 14.8 9.69999C14.8 8.19999 13.6 6.79999 12 6.79999C10.4 6.79999 9.20005 8.09999 9.20005 9.69999ZM17.5 14.1L12 20.8L6.50005 14C4.40005 11.5 4.40005 7.79999 6.50005 5.29999C9.40005 1.79999 14.7 1.79999 17.6 5.29999C19.6 7.89999 19.6 11.5 17.5 14.1Z"
        stroke="currentColor"
        stroke-width="1.5"
    />
</svg>
