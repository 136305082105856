<script lang="ts">
    import { ariaKeyDownA11yHandler } from '$src/utils/ariaKeyDownA11y';

    export let label: string;
    export let onToggleCheck: () => void | undefined = () => {};
    export let labelClass: string | undefined = '';
    export let isChecked: boolean = false;
</script>

<div
    class="flex cursor-pointer justify-between p-xs"
    on:click={onToggleCheck}
    role="button"
    tabindex="0"
    {...ariaKeyDownA11yHandler(onToggleCheck)}
>
    <div class={labelClass}>{label}</div>
    <div class="size-m">
        <input type="checkbox" checked={isChecked} />
    </div>
</div>
