<script lang="ts">
    import dayjs from 'dayjs';
    import { createDatePicker } from '@melt-ui/svelte';
    import { parseDate, DateValue } from '@internationalized/date';

    import CalendarPicker from '$src/components/CalendarPicker.svelte';
    import Flexibility from '$src/components/Filters/Flexibility.svelte';
    import Button from '$src/components/Button.svelte';

    export let startDateValue: DateValue | undefined;
    export let flexibility = '0';
    export let handleValidateSelection: (value: {
        startDate: DateValue | undefined;
        flexibility: string;
    }) => void;

    const {
        elements: {
            calendar,
            cell,
            grid,
            heading,
            nextButton,
            prevButton,
            content,
        },
        states: { value, months, headingValue, weekdays },
        helpers: { isDateDisabled, isDateUnavailable },
    } = createDatePicker({
        locale: 'fr',
        minValue: parseDate(dayjs().add(1, 'day').format('YYYY-MM-DD')),
        defaultValue: startDateValue,
    });

    const handleValidateDate = () => {
        handleValidateSelection({
            startDate: $value,
            flexibility,
        });
    };
</script>

<div
    {...content}
    use:content
    class="h-[calc(100vh-76px)] overflow-scroll pb-6xl pt-m"
>
    <div>
        <CalendarPicker
            {calendar}
            {prevButton}
            {heading}
            {headingValue}
            {nextButton}
            {months}
            {grid}
            {weekdays}
            {isDateDisabled}
            {isDateUnavailable}
            {cell}
            isMultipleMonths={false}
        />

        <!-- Flexibility -->
        <Flexibility bind:flexibility />
    </div>

    <!--  Footer validate button -->
    <div
        class="fixed bottom-0 left-0 flex w-full justify-between gap-5xl border-t border-solid border-commercial-neutral bg-white px-2xl py-m"
    >
        <Button
            type="button"
            intent="link"
            class="text-sm"
            size="none"
            on:click={() =>
                handleValidateSelection({
                    startDate: undefined,
                    flexibility: '0',
                })}
        >
            Effacer
        </Button>
        <Button
            type="button"
            size="small"
            shape="rounded"
            on:click={handleValidateDate}
        >
            Valider
        </Button>
    </div>
</div>
