<script lang="ts">
    import { createSlider } from '@melt-ui/svelte';

    export let defaultMin: number, defaultMax: number;
    export let label: string;
    export let valueMin: number;
    export let valueMax: number;
    export let hasChanged = false;

    const {
        elements: { root, range, thumbs },
        states: { value },
    } = createSlider({
        defaultValue: [valueMin, valueMax],
        max: defaultMax,
        min: defaultMin,
        onValueChange: ({ next }) => {
            valueMin = next[0];
            valueMax = next[1];
            hasChanged = true;

            return next;
        },
    });
</script>

<div class="flex flex-col">
    <span
        {...$root}
        use:root
        class="relative m-auto flex h-[40px] w-full items-center desktop:w-[300px]"
    >
        <span class="h-[2px] w-full bg-bord-quiet">
            <span {...$range} use:range class="h-[2px] bg-content-quiet" />
        </span>

        {#each $thumbs as thumb}
            <span
                {...thumb}
                use:thumb.action
                class="focus:ring-m relative size-m cursor-pointer rounded-full bg-content-quiet focus:!ring-content-quiet"
            >
            </span>
        {/each}
    </span>
    <div class="flex justify-between">
        <div>
            <span class="text-xs">Min.</span>
            {$value[0]}{label}
        </div>
        <div>
            <span class="text-xs">Max.</span>
            {$value[1]}{label}
        </div>
    </div>
</div>
