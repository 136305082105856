import { DateValue } from '@internationalized/date';

// Typing for the search form
export type FilterFormValues = {
    destination: string;
    theme: string;
    startDate: DateValue | undefined;
    flexibility: number;
    adults: string;
    children: string;
    childrenAges: string[];
    levels: string[];
    types: string[];
    tags: string[];
    budgetMin: number;
    budgetMax: number;
    durationMin: number;
    durationMax: number;
};

// Typing for the label displayed
export type FilterFormLabels = {
    destination: string;
    theme: string;
    startDate: DateValue | undefined;
    nbPax: string;
    levels: string;
    types: string;
    tags: string;
    budget: string;
    duration: string;
};

// used for mobile modal options listing
export enum FilterType {
    SELECTION,
    DESTINATION,
    SPORT,
    STARTDATE,
    PAX,
    LEVELS,
    TYPES,
    TAGS,
    BUDGET,
    DURATION,
}

// typing for param passed from the url
export type SearchForm = {
    destinations: string[] | number[] | null;
    sports: string[] | number[] | null;
    flexibility: number | null;
    nbPax: number | null;
    groupOnly: boolean;
    startDate: { date: string } | null;
    levels: string[];
    types: string[];
    tags: string[];
    budgetMin: number;
    budgetMax: number;
    durationMin: number;
    durationMax: number;
};
