<script lang="ts">
    import { createSelect } from '@melt-ui/svelte';
    import { fade } from 'svelte/transition';
    import {
        VpChevronDownIcon,
        VpChevronUpIcon,
    } from '@decathlon-experience/icons/svelte';

    import {
        FilteredValues,
        isDefaultOption,
        DefaultOptions,
    } from '$src/types/search.types';
    import cn from '$src/utils/cn';
    import CheckboxElement from '$src/views/List/Search/common/CheckboxElement.svelte';
    import { isParent } from '$src/utils/searchFilters';
    import { findCurrentSelectedFilterLabel } from '$src/views/List/Search/utils';

    export let inputLabel: string;
    export let options: DefaultOptions;
    export let onOutsideClick: (event: PointerEvent) => void;
    export let formValue: string[];

    let filteredOptions: FilteredValues | DefaultOptions = options;
    let hasChanged = false;

    const {
        elements: { trigger, menu, option, group, groupLabel, label },
        states: { open, selected },
    } = createSelect({
        forceVisible: true,
        positioning: {
            placement: 'bottom-start',
            strategy: 'fixed',
        },
        preventScroll: false,
        onSelectedChange: ({ next }) => {
            // only save ids
            formValue = next?.map((val) => val.value as string) ?? [];
            hasChanged = true;
            return next;
        },
        multiple: true,
        defaultSelected: formValue.map((initialValue) => ({
            value: initialValue,
            label: findCurrentSelectedFilterLabel(
                initialValue,
                filteredOptions as DefaultOptions,
            ),
        })),
        onOutsideClick: (event) =>
            hasChanged ? onOutsideClick(event) : () => {},
    });
</script>

<div
    class={cn(
        'flex w-auto flex-col gap-xs p-m text-content-neutral hover:text-content-quiet',
        $open && 'text-content-brand',
    )}
    {...$trigger}
    use:trigger
    role="button"
    tabindex="0"
>
    <div class="flex gap-m">
        <span class="flex font-semibold leading-6" {...$label} use:label>
            {inputLabel}
        </span>
        {#if $open}
            <VpChevronUpIcon class="text-content-brand" />
        {:else}
            <VpChevronDownIcon class="text-content-brand" />
        {/if}
    </div>
</div>

{#if $open}
    <div
        class="z-10 flex max-h-[400px] flex-col overflow-y-auto border border-solid border-border-quiet bg-white px-2xl py-m"
        {...$menu}
        use:menu
        transition:fade={{ duration: 150 }}
    >
        {#if filteredOptions}
            {#each filteredOptions as defaultOption}
                {#if !defaultOption.hidden}
                    <div {...$group(defaultOption.value)} use:group>
                        <div
                            class={cn(
                                'cursor-pointer leading-6 text-content-neutral',
                                isParent(defaultOption) && 'font-semibold',
                            )}
                            use:option
                            {...$option({
                                value: defaultOption.value,
                                label: defaultOption.label,
                            })}
                            use:groupLabel
                            {...$groupLabel(defaultOption.label)}
                            role="button"
                            tabindex="0"
                        >
                            <CheckboxElement
                                label={defaultOption.label}
                                labelClass={cn('mr-m')}
                                isChecked={$selected?.find(
                                    (selectedItem) =>
                                        selectedItem.value ===
                                        defaultOption.value,
                                ) != null}
                            />
                        </div>
                        {#if isDefaultOption(defaultOption) && defaultOption.children}
                            {#each defaultOption.children as item}
                                {#if !item.hidden}
                                    <div
                                        class="relative ml-m cursor-pointer text-content-neutral"
                                        use:option
                                        {...$option({
                                            value: item.value,
                                            label: item.label,
                                        })}
                                        role="button"
                                        tabindex="0"
                                    >
                                        <CheckboxElement
                                            label={item.label}
                                            isChecked={$selected?.find(
                                                (selectedItem) =>
                                                    selectedItem.value ===
                                                    item.value,
                                            ) != null}
                                            labelClass="mr-m"
                                        />
                                    </div>
                                {/if}
                            {/each}
                        {/if}
                    </div>
                {/if}
            {/each}
        {/if}
    </div>
{/if}

<style lang="postcss">
    [data-melt-select-option] {
        @apply hover:bg-commercial-neutral;
    }
</style>
