<script lang="ts">
    import cn from '$src/utils/cn';

    export let difficultyLevel: number, isCurrentDifficultyLevel: boolean;
</script>

<div
    class={cn(
        'flex gap-[2px] p-[3px] opacity-[0.38]',
        isCurrentDifficultyLevel && 'opacity-100',
    )}
>
    <div
        class={cn(
            'size-[14px] rounded-l-full bg-container-commercial-neutral',
            difficultyLevel === 0 && 'bg-container-brand',
        )}
    ></div>
    <div
        class={cn(
            'size-[14px] bg-container-commercial-neutral',
            difficultyLevel === 1 && 'bg-container-brand',
        )}
    ></div>
    <div
        class={cn(
            'size-[14px] bg-container-commercial-neutral',
            difficultyLevel === 2 && 'bg-container-brand',
        )}
    ></div>
    <div
        class={cn(
            'size-[14px] bg-container-commercial-neutral',
            difficultyLevel === 3 && 'bg-container-brand',
        )}
    ></div>
    <div
        class={cn(
            'size-[14px] rounded-r-full bg-container-commercial-neutral',
            difficultyLevel === 4 && 'bg-container-brand',
        )}
    ></div>
</div>
