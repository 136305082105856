<script lang="ts">
    import dayjs from 'dayjs';

    import { FilterFormLabels, FilterType } from '../types';

    export let handleSelectedFilter: (filterType: FilterType) => void;
    export let formLabels: FilterFormLabels;
</script>

<div
    class="mb-6xl flex h-full w-full flex-col gap-4 overflow-scroll p-m pb-[220px]"
>
    <button
        type="button"
        class="flex items-center justify-between bg-white p-m font-semibold leading-6 text-content-neutral"
        on:click={() => handleSelectedFilter(FilterType.DESTINATION)}
    >
        <span class="text-left text-content-quiet">Destination</span>
        {#if formLabels.destination !== ''}
            {formLabels.destination}
        {:else}
            Sélectionner
        {/if}
    </button>
    <button
        type="button"
        class="flex items-center justify-between bg-white p-m font-semibold leading-6 text-content-neutral"
        on:click={() => handleSelectedFilter(FilterType.SPORT)}
    >
        <span class="text-left text-content-quiet">Sport</span>
        {#if formLabels.theme !== ''}
            {formLabels.theme}
        {:else}
            Sélectionner
        {/if}
    </button>

    <button
        type="button"
        class="flex items-center justify-between bg-white p-m font-semibold leading-6 text-content-neutral"
        on:click={() => handleSelectedFilter(FilterType.STARTDATE)}
    >
        <span class="text-left text-content-quiet">Date de départ</span>
        {#if formLabels.startDate != null}
            {dayjs(formLabels.startDate.toString()).format('DD/MM/YYYY')}
        {:else}
            Sélectionner
        {/if}
    </button>
    <button
        type="button"
        class="flex items-center justify-between bg-white p-m font-semibold leading-6 text-content-neutral"
        on:click={() => handleSelectedFilter(FilterType.PAX)}
    >
        <span class="text-left text-content-quiet">Voyageurs</span>
        {#if formLabels.nbPax !== '' && formLabels.nbPax !== '0'}
            {formLabels.nbPax}
        {:else}
            Sélectionner
        {/if}
    </button>
    <!-- Secondary Filters -->
    <button
        type="button"
        class="flex items-center justify-between bg-white p-m font-semibold leading-6 text-content-neutral"
        on:click={() => handleSelectedFilter(FilterType.LEVELS)}
    >
        <span class="text-left text-content-quiet">Niveau sport</span>
        <span class="line-clamp-1">
            {#if formLabels.levels.length > 0}
                {formLabels.levels}
            {:else}
                Sélectionner
            {/if}
        </span>
    </button>
    <button
        type="button"
        class="flex items-center justify-between bg-white p-m font-semibold leading-6 text-content-neutral"
        on:click={() => handleSelectedFilter(FilterType.TYPES)}
    >
        <span class="text-left text-content-quiet">Type de séjour</span>
        <span class="line-clamp-1">
            {#if formLabels.types.length > 0}
                {formLabels.types}
            {:else}
                Sélectionner
            {/if}
        </span>
    </button>
    <button
        type="button"
        class="flex items-center justify-between bg-white p-m font-semibold leading-6 text-content-neutral"
        on:click={() => handleSelectedFilter(FilterType.DURATION)}
    >
        <span class="text-left text-content-quiet">Durée du séjour</span>
        {#if formLabels.duration !== ''}
            {formLabels.duration}
        {:else}
            Sélectionner
        {/if}
    </button>
    <button
        type="button"
        class="flex items-center justify-between bg-white p-m font-semibold leading-6 text-content-neutral"
        on:click={() => handleSelectedFilter(FilterType.BUDGET)}
    >
        <span class="text-left text-content-quiet">Budget par personne</span>
        {#if formLabels.budget !== ''}
            {formLabels.budget}
        {:else}
            Sélectionner
        {/if}
    </button>
    <button
        type="button"
        class="flex items-center justify-between bg-white p-m font-semibold leading-6 text-content-neutral"
        on:click={() => handleSelectedFilter(FilterType.TAGS)}
    >
        <span class="text-left text-content-quiet">Thème</span>
        <span class="line-clamp-1">
            {#if formLabels.tags.length > 0}
                {formLabels.tags}
            {:else}
                Sélectionner
            {/if}
        </span>
    </button>
</div>
