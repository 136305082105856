<script lang="ts">
    import { ComponentType } from 'svelte';

    import { DefaultOptions, FilteredValues } from '$src/types/search.types';
    import OptionListMobile from './OptionListMobile.svelte';
    import OptionListDesktop from '$src/components/Filters/desktop/OptionListDesktop.svelte';

    export let defaultOptions: DefaultOptions | undefined;
    export let inputLabel: string;
    export let inputName: string;
    export let menuTitle: string | undefined;
    export let icon: ComponentType;
    export let placeholder: string;
    export let placeholderMobile: string;
    export let defaultValueLabel: string;
    const defaultValue = {
        value: '',
        label: defaultValueLabel,
    };

    let inputValue: string = '';

    let filteredOptions: FilteredValues | DefaultOptions | undefined = [
        defaultValue,
        ...(defaultOptions ?? []),
    ];

    const filterOptions = (query: string) => {
        if (query !== '' && defaultOptions) {
            filteredOptions = defaultOptions.reduce((list, currentOption) => {
                if (
                    currentOption.label
                        .toLowerCase()
                        .includes(query.toLowerCase())
                ) {
                    list = [
                        ...list,
                        {
                            label: currentOption.label,
                            value: currentOption.value,
                        },
                    ];
                }
                if (currentOption?.children) {
                    currentOption.children.forEach((child) => {
                        if (
                            child.label
                                .toLowerCase()
                                .includes(query.toLowerCase())
                        ) {
                            list = [
                                ...list,
                                { label: child.label, value: child.value },
                            ];
                        }
                    });
                }
                return list;
            }, [] as FilteredValues);
        } else {
            // reset filtered array
            filteredOptions = [defaultValue, ...(defaultOptions ?? [])];
        }
    };

    const onInputChange = (event: Event) =>
        filterOptions((event.target as HTMLInputElement).value);

    const resetInput = () => {
        inputValue = '';
        // reset filtered options
        filterOptions('');
    };
</script>

<div class="hidden w-full desktop:block">
    <OptionListDesktop
        bind:inputValue
        {inputLabel}
        {menuTitle}
        {filteredOptions}
        {onInputChange}
        {resetInput}
        {icon}
        {placeholder}
    />
</div>

<div class="desktop:hidden">
    <OptionListMobile
        bind:inputValue
        {inputLabel}
        {menuTitle}
        {filteredOptions}
        {onInputChange}
        {resetInput}
        {icon}
        {placeholder}
        {placeholderMobile}
    />
</div>

<input
    aria-hidden="true"
    tabindex="-1"
    class="hidden"
    name={inputName}
    value={inputValue}
/>
