<script>
    import { onMount } from 'svelte';

    import VisibleDatesMobile from '$src/views/Tour/mobile/VisibleDates.svelte';
    import VisibleDatesDesktop from '$src/views/Tour/desktop/VisibleDates.svelte';

    export let tourDatesInfo,
        visibleDates,
        dict,
        isTravelAloneAuthorized,
        searchUrl;
</script>

<!--Mobile -->
<VisibleDatesMobile
    {tourDatesInfo}
    {visibleDates}
    {dict}
    {isTravelAloneAuthorized}
/>

<!--Desktop-->
<VisibleDatesDesktop
    {tourDatesInfo}
    {visibleDates}
    {dict}
    {isTravelAloneAuthorized}
    {searchUrl}
/>
