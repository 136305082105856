<script lang="ts">
    import Drawer from '$src/components/Drawer.svelte';
    import cn from '$src/utils/cn';

    export let url: string;
    export let label: string | null = null;
</script>

<Drawer titleLabel="Programme Fidélité">
    <span
        slot="triggerlink"
        class="inline-block w-fit cursor-pointer text-sm underline hover:no-underline"
    >
        {label ? label : 'En savoir plus'}
    </span>

    <div
        class="flex gap-m overflow-x-auto px-m desktop:justify-between desktop:px-3xl"
    >
        <div class="flex-1 self-end">
            <div
                class={cn(
                    'flex gap-2xs py-xs transition-colors duration-[0.25s]',
                )}
            >
                <div class="text-[20px] font-semibold">Explications</div>
            </div>
            <hr
                class={cn(
                    'border-t-1 w-full border-solid border-border-quiet transition-colors duration-[0.25s]',
                )}
            />
        </div>
    </div>

    <div class="mb-[15vh] mt-m flex flex-col gap-m px-l desktop:px-3xl">
        <div class="flex flex-col font-semibold">
            <span>
                Gagnez des points et dépensez-les pour vous équiper chez
                Decathlon !
            </span>
            <span>10 points de fidélité Decathlon pour chaque € dépensé !</span>
        </div>
        <div class="font-semibold">Comment ça marche ?</div>
        <ol class="!list-decimal pl-m">
            <li class="pb-2xs">
                Réservez votre voyage en étant connecté·e à votre compte
                Decathlon
            </li>
            <li>
                Recevez vos points de fidélité Decathlon dans un délai de 21
                jours à compter de la confirmation de votre réservation !
            </li>
        </ol>
        <div>
            <span>A titre d’exemple :</span>
            <ul class="!list-disc pl-l">
                <li class="pb-2xs">
                    1 séjour de 900€ = 9 000 points fidélité = une carte cadeau
                    Decathlon de 20€
                </li>
                <li>
                    1 séjour de 1 200€ = 12 000 points fidélité = une carte
                    cadeau Decathlon de 40€
                </li>
            </ul>
        </div>
        <div class="font-semibold">Conditions</div>
        <div class="text-justify">
            Vous devez être connecté·e à votre compte Decathlon au moment de la
            réservation du voyage. Les réservations effectuées sans connexion
            préalable au compte client ne pourront être éligibles à l’offre. Le
            nombre de points qui sera crédité correspond au montant total du
            voyage au moment de la réservation (hors compensation carbone). Les
            options ajoutées suite au 1er paiement ne pourront être
            comptabilisées.
        </div>

        <a class="underline hover:no-underline" href={url}
            >Voir toutes les conditions générales d’utilisation</a
        >
    </div>
</Drawer>
