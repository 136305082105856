import type {
    DefaultOption,
    DefaultOptions,
    FilteredValues,
} from '$src/types/search.types';
// $src impossible, weird compilation bug?
import { isDefaultOption } from '../types/search.types';

export const flattenSportsFilter = (sports: DefaultOptions) => {
    const flattenRecursive = (options: DefaultOptions): DefaultOptions => {
        let flattened: DefaultOptions = [];
        options.forEach((option) => {
            flattened.push({
                value: option.value,
                label: option.label,
                hidden: option.hidden,
            });
            if (option.children && option.children.length > 0) {
                flattened = flattened.concat(flattenRecursive(option.children));
            }
        });
        return flattened;
    };

    return flattenRecursive(sports).sort((a, b) =>
        a.label.localeCompare(b.label),
    );
};

export const isParent = (
    defaultOption: DefaultOption | FilteredValues[number],
) => {
    return (
        isDefaultOption(defaultOption) &&
        defaultOption.children &&
        defaultOption.children.length > 0
    );
};
