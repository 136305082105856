<svg
    {...$$props}
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
>
    <path
        d="M4.114 12a2 2 0 0 1 3.773 0h6.78v1.333h-6.78a2 2 0 0 1-3.773 0h-2.78V12h2.78Zm4-4.667a2 2 0 0 1 3.773 0h2.78v1.334h-2.78a2 2 0 0 1-3.774 0h-6.78V7.333h6.78Zm-4-4.666a2 2 0 0 1 3.773 0h6.78V4h-6.78a2 2 0 0 1-3.773 0h-2.78V2.667h2.78ZM6 4a.667.667 0 1 0 0-1.333A.667.667 0 0 0 6 4Zm4 4.667a.667.667 0 1 0 0-1.334.667.667 0 0 0 0 1.334Zm-4 4.666A.667.667 0 1 0 6 12a.667.667 0 0 0 0 1.333Z"
    />
</svg>
