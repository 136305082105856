<script lang="ts">
    import Slider from '$src/components/Slider.svelte';
    import Button from '$src/components/Button.svelte';

    export let defaultMin: number, defaultMax: number;
    export let label: string;
    export let valueMin: number;
    export let valueMax: number;
    export let handleValidateSelection: (values: {
        min: number;
        max: number;
    }) => void;

    let tempMin: number = valueMin;
    let tempMax: number = valueMax;
</script>

<div class="h-[calc(100vh-76px)] w-full overflow-scroll p-m px-3xl pb-[80px]">
    <Slider
        {defaultMin}
        {defaultMax}
        {label}
        bind:valueMin={tempMin}
        bind:valueMax={tempMax}
    />

    <!--  Footer validate button -->
    <div
        class="fixed bottom-0 left-0 flex w-full justify-between gap-5xl border-t border-solid border-commercial-neutral bg-white px-2xl py-m"
    >
        <Button
            type="button"
            intent="link"
            class="text-sm"
            size="none"
            on:click={() =>
                handleValidateSelection({
                    min: defaultMin,
                    max: defaultMax,
                })}>Effacer</Button
        >
        <Button
            shape="rounded"
            type="button"
            size="small"
            on:click={() =>
                handleValidateSelection({
                    min: tempMin,
                    max: tempMax,
                })}>Valider</Button
        >
    </div>
</div>
