<!-- TODO merge with MultiSelectFilter as one Select component with "multi" option -->
<script lang="ts">
    import { ComponentType } from 'svelte';

    import OptionListDesktop from '$src/components/Filters/desktop/OptionListDesktop.svelte';
    import { DefaultOptions, FilteredValues } from '$src/types/search.types';

    export let options: DefaultOptions;
    export let inputValue: string;

    export let icon: ComponentType;
    export let placeholder: string;
    export let inputLabel: string;
    export let menuTitle: string | undefined;
    export let defaultValueLabel: string;
    const defaultValue = {
        value: '',
        label: defaultValueLabel,
    };
    let filteredOptions: FilteredValues | DefaultOptions | undefined = [
        defaultValue,
        ...(options ?? []),
    ];

    const filterOptions = (query: string) => {
        if (query !== '' && options) {
            filteredOptions = options.reduce((list, currentOption) => {
                if (
                    currentOption.label
                        .toLowerCase()
                        .includes(query.toLowerCase())
                ) {
                    list = [
                        ...list,
                        {
                            label: currentOption.label,
                            value: currentOption.value,
                        },
                    ];
                }
                if (currentOption?.children) {
                    currentOption.children.forEach((child) => {
                        if (
                            child.label
                                .toLowerCase()
                                .includes(query.toLowerCase())
                        ) {
                            list = [
                                ...list,
                                { label: child.label, value: child.value },
                            ];
                        }
                    });
                }
                return list;
            }, [] as FilteredValues);
        } else {
            // reset filtered array
            filteredOptions = [defaultValue, ...(options ?? [])];
        }
    };

    const onInputChange = (event: Event) =>
        filterOptions((event.target as HTMLInputElement).value);

    const resetInput = () => {
        // reset filtered options
        filterOptions('');
    };
</script>

<OptionListDesktop
    bind:inputValue
    {onInputChange}
    {resetInput}
    {filteredOptions}
    {inputLabel}
    {menuTitle}
    {icon}
    {placeholder}
/>
