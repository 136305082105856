export interface DefaultOption {
    children?: DefaultOptions;
    value: string;
    label: string;
    hidden: boolean;
}

export type DefaultOptions = DefaultOption[];

export type DefaultOptionList = {
    destinations: DefaultOptions;
    environments: DefaultOptions;
    levels: DefaultOptions;
    sports: DefaultOptions;
    months: DefaultOptions;
    tags: DefaultOptions;
    types: DefaultOptions;
    durationMax: number;
    durationMin: number;
    budgetMax: number;
    budgetMin: number;
};

// Typing for one option, as a filtered list (through input search)
export type FilteredValues = { label: string; value: string }[];

// Type guard
export const isDefaultOption = (
    option: FilteredValues[number] | DefaultOptions[number],
): option is DefaultOption => {
    return (option as DefaultOption).children !== undefined;
};

// Option list as checkbox selection
export type MultiSelectFilterValues = {
    [key: string]: FilteredValues[number];
};
