<script lang="ts">
    import dayjs from 'dayjs';
    import { DateValue } from '@internationalized/date';

    import CalendarMobile from './CalendarMobile.svelte';
    import CalendarDesktop from '$src/components/Filters/desktop/CalendarDesktop.svelte';

    let startDateValue: DateValue | undefined;
    let flexibility: string;
</script>

<div class="hidden w-full desktop:block">
    <CalendarDesktop bind:startDateValue bind:flexibility />
</div>

<div class="desktop:hidden">
    <CalendarMobile bind:startDateValue bind:flexibility />
</div>

<input
    aria-hidden="true"
    tabindex="-1"
    class="hidden"
    name="startDate"
    value={startDateValue
        ? dayjs(startDateValue.toString()).format('DD/MM/YYYY')
        : ''}
/>

<input
    aria-hidden="true"
    tabindex="-1"
    class="hidden"
    name="flexibility"
    value={flexibility}
/>
