// remove date with 1 pax remaining if tour
// does not allow solo travel
export const filterTourDatesIsTravelAloneAuthorized = (
    dates,
    isTravelAloneAuthorized,
) => {
    return isTravelAloneAuthorized
        ? dates
        : dates.filter((date) => !date.isFull && date.remaining_stock > 1);
};
