<script lang="ts">
    import { createTooltip } from '@melt-ui/svelte';
    import { fade } from 'svelte/transition';

    import cn from '$src/utils/cn';

    export let tooltipContent: string, tooltipLabel: string;
    export let className: string = '';

    const {
        elements: { trigger, content },
        states: { open },
    } = createTooltip({
        positioning: {
            placement: 'top',
        },
        openDelay: 0,
        closeDelay: 0,
        closeOnPointerDown: false,
        forceVisible: true,
    });
</script>

<div
    class={cn('flex flex-wrap', className)}
    {...$trigger}
    use:trigger
    role="button"
    tabindex="0"
    on:click={() => ($open = true)}
>
    {tooltipLabel}
    {#if $open}
        <div
            {...$content}
            use:content
            transition:fade={{ duration: 100 }}
            class="absolute left-0 top-0 z-10 w-[350px] border border-solid border-border-quiet bg-white p-m text-sm"
        >
            <!-- eslint-disable-next-line svelte/no-at-html-tags -->
            {@html tooltipContent}
        </div>
    {/if}
</div>
