import './js/sentry';
import * as Turbo from '@hotwired/turbo';
import { registerSvelteControllerComponents } from '@symfony/ux-svelte';
import 'core-js/actual/structured-clone';

registerSvelteControllerComponents(
    require.context('./svelte/controllers', true, /\.svelte$/),
);

Turbo.session.drive = false;

import './css/main.scss';
import '@splidejs/splide/css';
import './js/consent-payment';

// start the Stimulus application
import './bootstrap';
