<script lang="ts">
    import { VpCloseIcon } from '@decathlon-experience/icons/svelte';

    import { ariaKeyDownA11yHandler } from '$src/utils/ariaKeyDownA11y';

    export let label: string;
    export let handleRemoveBadgeFilter: () => void;
</script>

<div
    class="cursor-pointer py-xs"
    id="travel-search-engine-selection"
    on:click={handleRemoveBadgeFilter}
    {...ariaKeyDownA11yHandler(handleRemoveBadgeFilter)}
    role="button"
    tabindex="0"
>
    <div
        class="flex items-center gap-xs rounded-[32px] border border-solid border-border-quiet bg-white p-xs text-xs uppercase text-content-brand"
    >
        <div><VpCloseIcon class="size-l" /></div>
        <div>{label}</div>
    </div>
</div>
