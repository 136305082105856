<script>
    import { TOUR_TYPE_GIR, TYPEBOOKING_EBOOKING } from '$src/constants/tour';
    import NumberInput from '$src/components/NumberInput.svelte';

    export let ageRules,
        isTravelAloneAuthorized,
        minAgeAllowed,
        maxAgeAllowed,
        tourType,
        bookingType,
        formValues,
        maxRuleValue,
        dateStatusName;
    const isEbookingType = bookingType === TYPEBOOKING_EBOOKING;
    const isGir = tourType === TOUR_TYPE_GIR;
    const minRuleValue =
        isEbookingType && ageRules.max.isUnder18Allowed
            ? 0
            : isTravelAloneAuthorized
            ? 1
            : 2;
</script>

<div
    class="items-center justify-between {ageRules.max.isUnder18Allowed
        ? 'hidden'
        : 'flex'}"
>
    <label for="nbAdults" class="flex flex-col text-content-neutral">
        {#if isEbookingType}
            <span class="font-semibold"> Adultes </span>
            <span class="text-sm text-content-quiet">
                <!-- 18ans et plus -->
                {#if (!minAgeAllowed || minAgeAllowed < 18) && !maxAgeAllowed}
                    18 ans et plus
                {/if}
                <!-- de 18ans à X -->
                {#if (!minAgeAllowed || minAgeAllowed < 18) && maxAgeAllowed >= 18}
                    18 à {maxAgeAllowed} ans
                {/if}
                <!--de X ans à Y ans -->
                {#if minAgeAllowed >= 18 && maxAgeAllowed >= 18}
                    {minAgeAllowed} à {maxAgeAllowed} ans
                {/if}
            </span>
        {:else}
            <span class="font-semibold">Nombre de voyageurs</span>
        {/if}
    </label>
    <NumberInput
        bind:value={formValues.adults}
        max={maxRuleValue}
        min={minRuleValue}
        id="nbAdults"
        name="nbAdults"
        minusBtnClasses={formValues.adults === minRuleValue && 'opacity-[0.38]'}
        minusBtnProps={{ disabled: formValues.adults === minRuleValue }}
        onDecrement={() => {
            if (formValues.adults > minRuleValue) {
                formValues.adults = formValues.adults - 1;
            }
        }}
        inputClasses={'focus:!border-blue-brand'}
        plusBtnClasses={maxRuleValue === formValues.adults && 'opacity-[0.38]'}
        plusBtnProps={{ disabled: maxRuleValue === formValues.adults }}
        onIncrement={() => {
            if (maxRuleValue == null || maxRuleValue > formValues.adults) {
                formValues.adults = formValues.adults + 1;
            }
        }}
    />
</div>

{#if formValues.selectedDate && !isEbookingType && isGir}
    <div class="mt-m">
        <strong> {dateStatusName}</strong>
        <p class="text-content-quiet">
            Nous avons besoin d’un certain nombre d’inscrits pour valider le
            départ et nous l’avons atteint, ce départ est confirmé !
        </p>
    </div>
{/if}
