<svg
    {...$$props}
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
>
    <path
        d="M12 10V11.5M17 10V11.5M7.00005 10V11.5M12 14V15.5M17 14V15.5M7.00005 14V15.5M8.90005 3V7.5M15.1 3V7.5M20.2001 19.2H3.80005C3.20005 19.2 2.80005 18.8 2.80005 18.2V5.2H21.3V18.2C21.2 18.8 20.8001 19.2 20.2001 19.2Z"
        stroke="currentColor"
        stroke-width="1.5"
    />
</svg>
