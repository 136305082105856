<script lang="ts">
    import { createSelect } from '@melt-ui/svelte';
    import { fade } from 'svelte/transition';
    import { ComponentType } from 'svelte';

    import {
        DefaultOptions,
        FilteredValues,
        isDefaultOption,
    } from '$src/types/search.types';
    import cn from '$src/utils/cn';
    import CloseCircle from '$src/icons/CloseCircle.svelte';
    import { isParent } from '$src/utils/searchFilters';
    import { findCurrentSelectedFilterLabel } from '$src/views/List/Search/utils';

    export let inputLabel: string;
    export let menuTitle: string | undefined;
    export let filteredOptions: FilteredValues | DefaultOptions | undefined;
    export let onInputChange: (event: Event) => void;
    export let resetInput: () => void;
    export let inputValue;
    export let icon: ComponentType;
    export let placeholder: string;

    let inputRef: HTMLInputElement;
    const {
        elements: { trigger, menu, option, group, groupLabel, label },
        states: { selectedLabel, selected, open },
    } = createSelect({
        forceVisible: true,
        positioning: {
            placement: 'bottom-start',
        },
        preventScroll: false,
        onSelectedChange: ({ next }) => {
            inputValue = next?.value;
            return next;
        },
        defaultSelected: {
            value: inputValue,
            label: findCurrentSelectedFilterLabel(
                inputValue,
                filteredOptions as DefaultOptions,
            ),
        },
    });
</script>

<div
    class="flex w-auto flex-col justify-normal gap-0.5 bg-container-quiet px-m py-xs hover:bg-secondary-regular-container-hover"
    {...$trigger}
    use:trigger
    role="button"
    tabindex="0"
    on:click={() => inputRef?.focus()}
>
    <span
        class="flex font-semibold leading-6 text-content-neutral"
        {...$label}
        use:label
    >
        <span class="mr-xs flex items-center">
            <svelte:component this={icon} class="size-m" />
        </span>
        {inputLabel}
    </span>
    <div class="flex w-full items-center">
        <input
            bind:this={inputRef}
            type="text"
            {placeholder}
            value={$selectedLabel}
            on:input={onInputChange}
        />

        <button
            type="button"
            class={cn('ml-xs', $selectedLabel === '' && 'hidden')}
            on:click={() => {
                selected.set(undefined);
                resetInput();
            }}
        >
            <CloseCircle class="size-m text-content-quiet" />
        </button>
    </div>
</div>

{#if $open}
    <div
        class="z-40 flex max-h-[400px] flex-col overflow-y-auto border border-solid border-bord-quiet bg-white p-2xl"
        {...$menu}
        use:menu
        transition:fade={{ duration: 150 }}
    >
        {#if menuTitle}
            <div class="mb-xs font-semibold leading-6 text-content-neutral">
                {menuTitle}
            </div>
        {/if}
        {#if filteredOptions}
            {#each filteredOptions as filteredOption}
                {#if !filteredOption.hidden}
                    <div {...$group(filteredOption.value)} use:group>
                        <div
                            class={cn(
                                'cursor-pointer p-m leading-6 text-content-neutral',
                                // if search not active (i.e. children[]) display parent in bold
                                isParent(filteredOption) && 'font-semibold',
                            )}
                            {...$groupLabel(filteredOption.label)}
                            use:groupLabel
                            use:option
                            {...$option({
                                value: filteredOption.value,
                                label: filteredOption.label,
                            })}
                        >
                            {filteredOption.label}
                        </div>
                        {#if isDefaultOption(filteredOption) && filteredOption.children}
                            {#each filteredOption.children as child}
                                {#if !child.hidden}
                                    <div
                                        class="relative ml-m cursor-pointer p-m text-content-neutral"
                                        {...$option({
                                            value: child.value,
                                            label: child.label,
                                        })}
                                        use:option
                                    >
                                        {child.label}
                                    </div>
                                {/if}
                            {/each}
                        {/if}
                    </div>
                {/if}
            {/each}
        {/if}
    </div>
{/if}

<style lang="postcss">
    [data-melt-select-option][data-highlighted] {
        @apply bg-commercial-neutral;
    }

    [data-melt-select-group-label][data-highlighted] {
        @apply bg-commercial-neutral;
    }

    input {
        appearance: unset;
        display: inline-block;
        margin: unset;
        padding: unset;
        max-width: unset;
        min-height: unset;
        outline: unset;
        background-color: inherit;
        font-family: inherit;
        font-size: inherit;
        color: inherit;
        border: unset;
        border-radius: unset;
        transition: unset;
        @apply block w-full text-left leading-6 text-content-neutral placeholder:text-rock-200 focus:placeholder:text-transparent;
    }
</style>
