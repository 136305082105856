<script lang="ts">
    import Search from '$src/views/HomePage/Search.svelte';

    import { DefaultOptionList, DefaultOptions } from '$src/types/search.types';
    import { flattenSportsFilter } from '$src/utils/searchFilters';

    export let defaultOptions: string;
    export let searchUrl: string;

    let defaultSportOptions: DefaultOptions | undefined;
    let defaultDestinationOptions: DefaultOptions | undefined;

    $: {
        const parsedOptions: DefaultOptionList = JSON.parse(defaultOptions);
        defaultDestinationOptions = parsedOptions?.destinations;

        // Flatten all children, not displaying parent sports
        defaultSportOptions = flattenSportsFilter(parsedOptions?.sports);
    }
</script>

<Search {defaultDestinationOptions} {defaultSportOptions} {searchUrl} />
