<script lang="ts">
    import { createPopover } from '@melt-ui/svelte';
    import { fade } from 'svelte/transition';
    import {
        VpChevronDownIcon,
        VpChevronUpIcon,
    } from '@decathlon-experience/icons/svelte';

    import Slider from '$src/components/Slider.svelte';
    import cn from '$src/utils/cn';

    export let defaultMin: number, defaultMax: number;
    export let valueMin: number, valueMax: number;
    export let label: string;
    export let title: string;
    export let onOutsideClick: (event: PointerEvent) => void;

    let hasChanged = false;

    const {
        elements: { trigger, content },
        states: { open },
    } = createPopover({
        forceVisible: true,
        onOutsideClick: (event) =>
            hasChanged ? onOutsideClick(event) : () => {},
    });
</script>

<div
    {...$trigger}
    use:trigger
    class={cn(
        'flex gap-m self-start p-m font-semibold hover:text-content-quiet',
        $open && 'text-content-brand',
    )}
>
    {title}
    {#if $open}
        <VpChevronUpIcon class="text-content-brand" />
    {:else}
        <VpChevronDownIcon class="text-content-brand" />
    {/if}
</div>

{#if $open}
    <div
        {...$content}
        use:content
        transition:fade={{ duration: 100 }}
        class="border border-solid border-bord-quiet bg-white p-2xl"
    >
        <Slider
            {defaultMin}
            {defaultMax}
            {label}
            bind:valueMin
            bind:valueMax
            bind:hasChanged
        />
    </div>
{/if}
