import { twMerge } from 'tailwind-merge';
import { cx } from 'class-variance-authority';

/*
 * Handles merging tw classes so that there is no clash between
 * 2 classes that operate on the same property
 * e.g. "h-full h-0"
 */
const cn = (...inputs) => twMerge(cx(inputs));

export default cn;
