import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
require('dayjs/locale/fr');
dayjs.extend(isBetween);
dayjs.locale('fr');

export interface DateRange {
    startDate: Dayjs;
    endDate: Dayjs;
}

// tour date format is DD/MM/YYYY
export const formatTourDate = (date: string) => {
    return dayjs(date).format('DD MMM');
};

export const formatTourDate2 = (date: string) => {
    return dayjs(date).format('DD MMM YYYY');
};

/**
 * Return the first available month index (dayjs index starts at 0)
 * @param unavailableDates
 * @returns dayjs Date
 */
export const findFirstAvailableMonth = (unavailableDates: DateRange[]) => {
    const currentDate = dayjs();
    const sortedUnavailableDates = unavailableDates.sort((a, b) =>
        a.endDate.isAfter(b.endDate) ? 1 : -1,
    );

    let earliestAvailableDate = currentDate;

    for (let i = 0; i < sortedUnavailableDates.length; i++) {
        const endDate = sortedUnavailableDates[i].endDate;
        const nextStartDate = sortedUnavailableDates[i + 1]?.startDate;

        if (endDate.isBefore(currentDate)) {
            continue;
        }

        if (!nextStartDate || endDate.add(1, 'day').isBefore(nextStartDate)) {
            earliestAvailableDate = endDate.add(1, 'day');
            break;
        }
    }

    return earliestAvailableDate;
};
