<script lang="ts">
    import type { Writable } from 'svelte/store';
    import { createDialog } from '@melt-ui/svelte';
    import { scale } from 'svelte/transition';
    import { quintOut } from 'svelte/easing';

    export let onClose: () => void | undefined = () => {};
    export let isOpen: Writable<boolean> | undefined;

    const {
        elements: { portalled, content },
        states: { open },
    } = createDialog({
        role: 'alertdialog',
        open: isOpen,
    });
</script>

<div {...$portalled} use:portalled>
    {#if $open}
        <div
            {...$content}
            use:content
            class="fixed left-0 top-0 z-50 h-full w-full overflow-y-scroll bg-white"
            transition:scale={{
                duration: 200,
                easing: quintOut,
            }}
        >
            <div
                class="min-h-[76px] border-b border-solid border-border-quiet p-xl"
            >
                <slot name="header" />
            </div>
            <slot />
        </div>
    {/if}
</div>

<!-- Override escape key event to trigger onClose callback -->
<svelte:window
    on:keydown={(event) => {
        if (event.key === 'Escape' && $open) {
            $open = false;
            onClose();
        }
    }}
/>
