<!-- TODO merge with MultiSelectFilter as one Select component with "multi" option -->
<script lang="ts">
    import cn from '$src/utils/cn';
    import {
        DefaultOptions,
        FilteredValues,
        isDefaultOption,
    } from '$src/types/search.types';
    import CloseCircle from '$src/icons/CloseCircle.svelte';
    import { isParent } from '$src/utils/searchFilters';

    export let options: DefaultOptions;
    export let handleValidateSelection: (
        values?: FilteredValues[number],
    ) => void;
    export let selectedValue: string;
    export let selectedLabel: string;
    export let defaultValueLabel: string;
    export let placeholder: string;

    const defaultValue = {
        value: '',
        label: defaultValueLabel,
    };
    let filteredOptions: FilteredValues | DefaultOptions | undefined = [
        defaultValue,
        ...(options ?? []),
    ];
    const filterOptions = (query: string) => {
        if (query !== '' && options) {
            filteredOptions = options.reduce((list, currentOption) => {
                if (
                    currentOption.label
                        .toLowerCase()
                        .includes(query.toLowerCase())
                ) {
                    list = [
                        ...list,
                        {
                            label: currentOption.label,
                            value: currentOption.value,
                        },
                    ];
                }
                if (currentOption?.children) {
                    currentOption.children.forEach((child) => {
                        if (
                            child.label
                                .toLowerCase()
                                .includes(query.toLowerCase())
                        ) {
                            list = [
                                ...list,
                                { label: child.label, value: child.value },
                            ];
                        }
                    });
                }
                return list;
            }, [] as FilteredValues);
        } else {
            // reset filtered array
            filteredOptions = [defaultValue, ...(options ?? [])];
        }
    };

    const handleSelection = (option: { value: string; label: string }) => {
        selectedLabel = option.label;
        handleValidateSelection(option);
    };

    const onInputChange = (event: Event) =>
        filterOptions((event.target as HTMLInputElement).value);

    const resetInput = () => {
        selectedValue = '';
        selectedLabel = '';
        handleValidateSelection(undefined);
        // reset filtered options
        filterOptions('');
    };
</script>

<div class="h-[calc(100vh-76px)] overflow-scroll p-2xl pb-6xl">
    <div class="flex w-full flex-col">
        <!-- Input -->
        <div class="my-m flex">
            <input
                type="text"
                on:input={onInputChange}
                bind:value={selectedLabel}
                {placeholder}
            />
            <button
                type="button"
                class={cn(
                    'bg-container-quiet pr-m',
                    selectedLabel === '' && 'hidden',
                )}
                on:click|preventDefault|stopPropagation={() => {
                    selectedLabel = '';
                    resetInput();
                }}
            >
                <CloseCircle class="size-m fill-content-quiet" />
            </button>
        </div>
        {#if filteredOptions}
            {#each filteredOptions as filteredOption}
                {#if !filteredOption.hidden}
                    <button
                        type="button"
                        on:click={() => handleSelection(filteredOption)}
                        class={cn(
                            'cursor-pointer p-m text-left leading-6 text-content-neutral',
                            // if search not active (i.e children[]) display parent in bold
                            isParent(filteredOption) && 'font-semibold',
                            selectedValue === filteredOption.value &&
                                'bg-commercial-neutral',
                        )}
                    >
                        {filteredOption.label}
                    </button>
                {/if}
                {#if isDefaultOption(filteredOption) && filteredOption.children}
                    {#each filteredOption.children as child}
                        {#if !child.hidden}
                            <button
                                type="button"
                                on:click={() => handleSelection(child)}
                                class={cn(
                                    'ml-m cursor-pointer p-m text-left text-content-neutral',
                                    selectedValue === child.value &&
                                        'bg-commercial-neutral',
                                )}
                            >
                                {child.label}
                            </button>
                        {/if}
                    {/each}
                {/if}
            {/each}
        {/if}
    </div>
</div>

<style lang="postcss">
    input {
        appearance: unset;
        display: inline-block;
        margin: unset;
        padding: unset;
        max-width: unset;
        min-height: unset;
        outline: unset;
        background-color: inherit;
        font-family: inherit;
        font-size: inherit;
        color: inherit;
        border: unset;
        border-radius: unset;
        transition: unset;
        @apply block w-full bg-container-quiet px-m py-xs leading-6 placeholder:text-rock-200;
    }
</style>
