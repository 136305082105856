<script>
    import { TOUR_TYPE_GIR, TYPEBOOKING_EBOOKING } from '$src/constants/tour';
    import AdultsInput from './AdultsInput.svelte';
    import ChildrenInput from './ChildrenInput.svelte';

    export let tourType;
    export let bookingType;
    export let isTravelAloneAuthorized;
    export let minAgeAllowed;
    export let maxAgeAllowed;
    export let formValues;
    export let childrenAges;
    export let ageRules;
    export let datesInfos;

    const isEbookingType = bookingType === TYPEBOOKING_EBOOKING;
    const isGir = tourType === TOUR_TYPE_GIR;

    $: maxAdultsRuleValue =
        isEbookingType && isGir
            ? formValues.selectedDate &&
              datesInfos.remainingStock - formValues.children
            : null;
    $: maxChildrenRuleValue = isGir
        ? datesInfos.remainingStock - formValues.adults
        : null;
</script>

<div class="mx-xl flex flex-col gap-xl pb-2xl pt-2xl">
    <AdultsInput
        {isTravelAloneAuthorized}
        {minAgeAllowed}
        {maxAgeAllowed}
        {tourType}
        {bookingType}
        bind:formValues
        {ageRules}
        maxRuleValue={maxAdultsRuleValue}
        dateStatusName={datesInfos?.statusName ?? ''}
    />
    {#if bookingType === TYPEBOOKING_EBOOKING}
        <ChildrenInput
            {minAgeAllowed}
            {maxAgeAllowed}
            bind:formValues
            {ageRules}
            {childrenAges}
            maxRuleValue={maxChildrenRuleValue}
        />
    {/if}
</div>
