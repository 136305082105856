<script lang="ts">
    import {
        DefaultOptions,
        FilteredValues,
        isDefaultOption,
        MultiSelectFilterValues,
    } from '$src/types/search.types';
    import CheckboxElement from '$src/views/List/Search/common/CheckboxElement.svelte';
    import Button from '$src/components/Button.svelte';
    import cn from '$src/utils/cn';
    import CloseCircle from '$src/icons/CloseCircle.svelte';

    export let options: DefaultOptions;
    export let handleValidateSelection: (values: typeof selectedValues) => void;
    export let selectedValues: MultiSelectFilterValues = {};
    export let defaultValueLabel: string = '';
    export let hasDefaultValue = false;
    export let hasSearch = true;

    const defaultValue = {
        value: '',
        label: defaultValueLabel,
    };

    let filteredOptions: FilteredValues | DefaultOptions | undefined = [
        ...(hasDefaultValue ? [...options, defaultValue] : options ?? []),
    ];
    let inputSearchValue: string = '';

    const filterOptions = (query: string) => {
        if (query !== '' && options) {
            filteredOptions = options.reduce((list, currentOption) => {
                if (
                    currentOption.label
                        .toLowerCase()
                        .includes(query.toLowerCase())
                ) {
                    list = [
                        ...list,
                        {
                            label: currentOption.label,
                            value: currentOption.value,
                        },
                    ];
                }
                if (currentOption?.children) {
                    currentOption.children.forEach((child) => {
                        if (
                            child.label
                                .toLowerCase()
                                .includes(query.toLowerCase())
                        ) {
                            list = [
                                ...list,
                                { label: child.label, value: child.value },
                            ];
                        }
                    });
                }
                return list;
            }, [] as FilteredValues);
        } else {
            // reset filtered array
            filteredOptions = [
                ...(hasDefaultValue
                    ? [...options, defaultValue]
                    : options ?? []),
            ];
        }
    };

    const handleToggle = (option: FilteredValues[number]) => {
        if (selectedValues[option.value]) {
            // remove via destructuring
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { [option.value]: remove, ...rest } = selectedValues;
            selectedValues = rest;
        } else {
            selectedValues = {
                ...selectedValues,
                [option.value]: {
                    ...option,
                },
            };
        }
    };

    const onInputChange = (event: Event) =>
        filterOptions((event.target as HTMLInputElement).value);

    const resetInput = () => {
        // reset filtered options
        filterOptions('');
    };
</script>

<div class="h-[calc(100vh-76px)] w-full overflow-scroll p-xs pb-6xl">
    {#if hasSearch}
        <!-- Input -->
        <div class="mb-m flex">
            <input
                type="text"
                on:input={onInputChange}
                bind:value={inputSearchValue}
            />
            <button
                type="button"
                class={cn(
                    'bg-container-quiet pr-m',
                    inputSearchValue === '' && 'hidden',
                )}
                on:click|preventDefault|stopPropagation={() => {
                    inputSearchValue = '';
                    resetInput();
                }}
            >
                <CloseCircle class="size-m fill-content-quiet" />
            </button>
        </div>
    {/if}

    {#if filteredOptions}
        {#each filteredOptions as defaultOption}
            {#if !defaultOption.hidden}
                <CheckboxElement
                    label={defaultOption.label}
                    onToggleCheck={() => handleToggle(defaultOption)}
                    isChecked={selectedValues[defaultOption.value] != null}
                />
            {/if}

            {#if isDefaultOption(defaultOption) && defaultOption.children}
                {#each defaultOption.children as item}
                    {#if !item.hidden}
                        <CheckboxElement
                            label={item.label}
                            isChecked={selectedValues[item.value] != null}
                            onToggleCheck={() => handleToggle(item)}
                            labelClass="ml-m"
                        />
                    {/if}
                {/each}
            {/if}
        {/each}
    {/if}
</div>
<div
    class="fixed bottom-0 left-0 flex w-full justify-between gap-5xl border-t border-solid border-commercial-neutral bg-white px-2xl py-m"
>
    <Button
        type="button"
        intent="link"
        class="text-sm"
        size="none"
        on:click={() => handleValidateSelection({})}>Effacer</Button
    >
    <Button
        type="button"
        shape="rounded"
        size="small"
        on:click={() => handleValidateSelection(selectedValues)}>Valider</Button
    >
</div>

<style lang="postcss">
    input {
        appearance: unset;
        display: inline-block;
        margin: unset;
        padding: unset;
        max-width: unset;
        min-height: unset;
        outline: unset;
        background-color: inherit;
        font-family: inherit;
        font-size: inherit;
        color: inherit;
        border: unset;
        border-radius: unset;
        transition: unset;
        @apply block w-full bg-container-quiet px-m py-xs leading-6 placeholder:text-rock-200;
    }
</style>
