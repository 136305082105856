<script lang="ts">
    import SearchBar from '$src/views/List/Search/SearchBar.svelte';
    import { DefaultOptionList } from '$src/types/search.types';

    export let defaultOptions: string;
    $: parsedDefaultOptions = JSON.parse(defaultOptions) as DefaultOptionList;

    export let searchForm: never;
</script>

<SearchBar defaultOptions={parsedDefaultOptions} {searchForm} />
