import { fly } from 'svelte/transition';

export const flyDrawer = (element: Element) => {
    const mediaQuery = '(min-width: 1300px)';
    if (window.matchMedia(mediaQuery).matches) {
        return fly(element, {
            x: '485px',
            duration: 500,
            opacity: 1,
        });
    }

    return fly(element, {
        y: '100vh',
        duration: 500,
        opacity: 1,
    });
};
