<script>
    import dayjs from 'dayjs';

    import cn from '$src/utils/cn';
    import { formatCurrency } from '$src/utils/price';
    import { formatTourDate, formatTourDate2 } from '$src/utils/date';
    import ChevronRightIcon from '$src/icons/ChevronRightIcon.svelte';

    export let tourDatesInfo, visibleDates, dict, searchUrl;

    let isShowingMore = false;

    const isDateRemainingStockLast = (date) => date.remaining_stock === 1;
    const isDateRemainingStockAvailable = (date) => date.remaining_stock > 0;
</script>

<div class="hidden desktop:block">
    <!-- Desktop version -->
    <h2 class="pb-sm text-[32px] font-semibold text-content-neutral">
        {dict.dates.title}
    </h2>
    {#if visibleDates.length > 0}
        <div class="w-full bg-white p-sm text-content-neutral">
            <form autocomplete="off">
                <table class="travel-tour-dates-table w-full">
                    <tbody>
                        {#each visibleDates as date, index}
                            <tr
                                data-travel-tour-dates-datarow
                                on:click={(event) => {
                                    const target =
                                        event.currentTarget.querySelector(
                                            'input[name="startDateTable"]',
                                        );
                                    // avoid event to be retriggered by the input
                                    if (event.target.tagName !== 'INPUT') {
                                        target.click();
                                    }
                                }}
                                class={cn(
                                    !date.isFull && 'cursor-pointer',
                                    'border-b border-solid border-bord-primary last:border-b-0',
                                    !isShowingMore && index > 4 && 'hidden',
                                )}
                            >
                                <td
                                    class={cn(
                                        'travel-tour-dates__dates w-1/4 p-xs text-left align-middle text-sm',
                                        date.isFull && 'opacity[0.38]',
                                    )}
                                    data-date={dayjs(date.date1.date).format(
                                        'YYYY-MM-DD',
                                    )}
                                >
                                    Du <strong
                                        >{formatTourDate(
                                            date.date1.date,
                                        )}</strong
                                    >
                                    au
                                    <strong
                                        >{formatTourDate2(
                                            date.date2.date,
                                        )}</strong
                                    >
                                </td>

                                {#if date.isFull}
                                    <td
                                        class="travel-tour-dates__status p-m text-center text-sm"
                                        data-tooltip-medium
                                        data-tooltip={dict.dates.full}
                                        data-status={date.statusName}
                                        data-date={dayjs(
                                            date.date1.date,
                                        ).format('YYYY-MM-DD')}
                                    >
                                        <strong class="text-content-brand">
                                            {date.statusName}
                                        </strong>
                                    </td>
                                {/if}

                                {#if date.isGuaranteed}
                                    <td
                                        class="travel-tour-dates__status p-xs text-center text-sm"
                                        data-tooltip-medium
                                        data-tooltip={dict.status.confirm}
                                        data-status={date.statusName}
                                        data-date={dayjs(
                                            date.date1.date,
                                        ).format('YYYY-MM-DD')}
                                    >
                                        <strong class="text-content-brand">
                                            {date.statusName}
                                        </strong>
                                        {#if isDateRemainingStockAvailable(date)}
                                            <br />
                                            <span
                                                class="travel-tour-dates__dates-available text-content-brand"
                                            >
                                                {date.remaining_stock} Place{!isDateRemainingStockLast(
                                                    date,
                                                )
                                                    ? 's'
                                                    : ''} disponible{!isDateRemainingStockLast(
                                                    date,
                                                )
                                                    ? 's'
                                                    : ''}
                                            </span>
                                        {/if}
                                    </td>
                                {/if}

                                {#if date.isAvailable}
                                    <td
                                        class="travel-tour-dates__status p-xs text-center text-sm"
                                        data-tooltip-medium
                                        data-tooltip={dict.status.available.replace(
                                            '%minPax%',
                                            date.countPaxNeededToConfirm,
                                        )}
                                        data-status={date.statusName}
                                        data-date={dayjs(
                                            date.date1.date,
                                        ).format('YYYY-MM-DD')}
                                    >
                                        <strong class="text-content-brand">
                                            {date.statusName}
                                        </strong>
                                        {#if isDateRemainingStockAvailable(date)}
                                            <br /><span
                                                class="travel-tour-dates__dates-available text-content-brand"
                                            >
                                                {date.remaining_stock} Place{!isDateRemainingStockLast(
                                                    date,
                                                ) && 's'} disponible{!isDateRemainingStockLast(
                                                    date,
                                                ) && 's'}
                                            </span>
                                        {/if}
                                    </td>
                                {/if}

                                <td class="p-xs text-left align-middle">
                                    <div
                                        class={cn(
                                            'flex items-center justify-end gap-xs',
                                            date.isFull && 'opacity-[0.38]',
                                        )}
                                    >
                                        {#if date.hasRebate}
                                            <strong
                                                class="travel-tour-dates__price line-through desktop:text-sm"
                                            >
                                                {formatCurrency(
                                                    date.priceWithCustomerCarbonContribution,
                                                )}
                                            </strong>
                                            <span
                                                class="bg-container-commercial-catchy p-2xs text-xs"
                                            >
                                                {date.rebateAmount}
                                            </span>
                                            <strong
                                                class="travel-tour-dates__price desktop:text-xl"
                                                data-date={dayjs(
                                                    date.date1.date,
                                                ).format('YYYY-MM-DD')}
                                                data-rebatedPrice={date.rebatedPriceWithCustomerCarbonContribution}
                                                data-formattedRebatedPrice={formatCurrency(
                                                    date.rebatedPriceWithCustomerCarbonContribution,
                                                )}
                                                data-rebatePercentage={date.rebateAmount}
                                                data-price={date.priceWithCustomerCarbonContribution}
                                                data-formattedPrice={formatCurrency(
                                                    date.priceWithCustomerCarbonContribution,
                                                )}
                                            >
                                                {formatCurrency(
                                                    date.rebatedPriceWithCustomerCarbonContribution,
                                                )}
                                            </strong>
                                        {:else}
                                            <strong
                                                class="travel-tour-dates__price desktop:text-xl"
                                                data-date={dayjs(
                                                    date.date1.date,
                                                ).format('YYYY-MM-DD')}
                                                data-price={date.priceWithCustomerCarbonContribution}
                                                data-formattedPrice={formatCurrency(
                                                    date.priceWithCustomerCarbonContribution,
                                                )}
                                            >
                                                {formatCurrency(
                                                    date.priceWithCustomerCarbonContribution,
                                                )}
                                            </strong>
                                        {/if}
                                    </div>
                                </td>

                                <td
                                    class={cn(
                                        'p-xs pl-[96px] text-left align-middle',
                                        date.isFull && 'opacity-[0.38]',
                                    )}
                                >
                                    <input
                                        name="startDateTable"
                                        type="radio"
                                        value={dayjs(date.date1.date).format(
                                            'YYYY-MM-DD',
                                        )}
                                        disabled={date.isFull}
                                    />
                                </td>
                            </tr>
                        {/each}
                    </tbody>
                </table>
            </form>

            {#if visibleDates.length > 5}
                <div class="my-m flex items-center justify-center">
                    <button
                        class="inline-block rounded-full border border-solid border-border-neutral bg-white px-l py-xs text-sm uppercase text-content-brand hover:bg-container-quiet"
                        on:click={() => (isShowingMore = !isShowingMore)}
                    >
                        {#if !isShowingMore}
                            {dict.dates.showmore}
                        {:else}
                            {dict.dates.showless}
                        {/if}
                    </button>
                </div>
            {/if}

            {#if tourDatesInfo}
                <div class="info bg-white p-sm italic text-content-neutral">
                    <h3 class="pb-sm text-[22px] text-content-neutral">
                        {dict.dates.dates_info}
                    </h3>
                    <div class="is-rt text-sm text-content-quiet">
                        <!-- eslint-disable-next-line svelte/no-at-html-tags -->
                        {@html tourDatesInfo}
                    </div>
                </div>
            {/if}
        </div>
    {:else}
        <div
            class="border-content-brand my-sm flex justify-between border border-solid bg-commercial-neutral p-sm text-content-brand"
        >
            <div class="flex gap-xs items-center">
                <!--<ErrorWarningIcon class="size-xl text-content-brand" />-->
                <span>{dict.sale.closed}</span>
            </div>
            <a href={searchUrl} class="flex items-center gap-xs underline whitespace-nowrap">
                {dict.sale.see_travels}
            </a>
        </div>
    {/if}
</div>
