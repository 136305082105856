const payButton = document.querySelector('#payButton');
const consentCheckbox = document.querySelector('#consent-checkbox');
const consentCheckboxBrand = document.querySelector('#consent-checkbox-brand');
const cgvMessage = document.querySelector('#cgvMessage');

consentCheckbox?.addEventListener('click', () => {
    payButton.disabled = !consentCheckbox.checked;
    cgvMessage.style.display = consentCheckbox.checked ? 'none' : 'block';
});

if (consentCheckboxBrand) {
    consentCheckboxBrand?.addEventListener('click', () => {
        payButton.disabled = !consentCheckboxBrand.checked;
        cgvMessage.style.display = consentCheckboxBrand.checked
            ? 'none'
            : 'block';
    });
}

payButton?.addEventListener('click', () => {
    if (!consentCheckbox.checked) {
        cgvMessage.style.display = 'block';
        return;
    }
    if (consentCheckboxBrand && !consentCheckboxBrand.checked) {
        cgvMessage.style.display = 'block';
        return;
    }

    window.payment.processPayment();
});
