import * as Sentry from '@sentry/browser';

Sentry.init({
    // eslint-disable-next-line no-undef
    dsn: SENTRY_BROWSER_DSN,
    // eslint-disable-next-line no-undef
    release: npm_package_version,
    ignoreErrors: ['_AutofillCallbackHandler'],
    // https://gist.github.com/jeengbe/4bc86f05a41a1831e6abf2369579cc7a
    // https://sentry.io/answers/load-failed-javascript/
    beforeSend: (error, hint) => {
        return shouldIgnoreError(error, hint) ? null : error;
    },
});

function shouldIgnoreError(error) {
    return isNetworkError(error);
}

function isNetworkError(error) {
    const exception = error.exception?.values?.[0];
    const now = Date.now();

    if (
        exception?.type !== 'TypeError' ||
        !['Load failed'].includes(exception.value)
    ) {
        return false;
    }

    if (!error.breadcrumbs) {
        return false;
    }

    // We go from the back since the last breadcrumb is most likely the erroneous one
    for (let i = error.breadcrumbs.length - 1; i >= 0; i--) {
        const breadcrumb = error.breadcrumbs[i];
        if (!breadcrumb) continue;

        // We only need to check the last 5s of breadcrumbs as any earlier breadcrumbs are definitely unrelated
        if (breadcrumb.timestamp && now - breadcrumb.timestamp * 1000 > 5000) {
            break;
        }

        if (isErroneousBreadcrumb(breadcrumb)) {
            return true;
        }
    }

    return false;
}

function isErroneousBreadcrumb(breadcrumb) {
    if (
        breadcrumb.level !== 'error' ||
        (breadcrumb.category !== 'xhr' && breadcrumb.category !== 'fetch')
    ) {
        return false;
    }

    const url = breadcrumb.data?.url | undefined;
    if (!url) return false;

    return url.startsWith(
        'https://api.iadvize.com',
        'https://ct.pinterest.com',
        'https://halc.iadvize.com',
    );
}
