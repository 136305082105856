<svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...$$props}
>
    <path
        d="M10.4 14.8L10 17.2L6.90002 20.3M15.6 20.7L13.7 15.9L10.9 13.1C10.8 13 10.7 12.8 10.8 12.6L12.1 8C12.2 7.6 11.8 7.2 11.4 7.4L8.70002 8.8L7.10002 11.4M11.9 7.5L15.3 10.5L17.9 11.2"
        stroke="currentColor"
        stroke-width="1.5"
    />
    <path
        d="M13.1 6.29999C13.9284 6.29999 14.6 5.62842 14.6 4.79999C14.6 3.97156 13.9284 3.29999 13.1 3.29999C12.2715 3.29999 11.6 3.97156 11.6 4.79999C11.6 5.62842 12.2715 6.29999 13.1 6.29999Z"
        fill="currentColor"
    />
</svg>
